import { Injectable } from '@angular/core';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import {
  ProcessDetailsDTO,
  ProcessFormDTO,
  ProcessListDTO,
} from '../dto/process.dto';
import { FormGroup } from '@angular/forms';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../users/conversion/user.conversion';
import { convertResponseToProcessGroupDetailsDTO } from '../../../settings/organization/dto/process-group.dto';
import { convertResponseToLifeCycleStatusDetailsDTO } from '../../../settings/masters/dto/life-cycle-status.dto';
import * as am5 from '@amcharts/amcharts5';
import { pieChartData } from '../../../../core/modals/chart.modal';
import { StatusDTO } from '../../../../shared/dto/status.dto';
import { convertResponseToChartData } from '../../../../core/modals/status.model';

@Injectable({
  providedIn: 'root',
})
export class ProcessConversion
  implements
    MainConversionInterface<ProcessListDTO, ProcessFormDTO, ProcessDetailsDTO>
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): ProcessListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        title: item[`title_${this.lang}`],
        description: item.description,
        process_group_title: item[`process_group_title_${this.lang}`],
        owner_unit_title: item[`owner_unit_title_${this.lang}`],
        process_owner: this.userConv.resToUserMinimalDTO(item, 'owner'),
        created_at: item.created_at,
        updated_at: item.updated_at,
        created_user: this.userConv.resToUserMinimalDTO(item, 'created_user'),
        updated_user: this.userConv.resToUserMinimalDTO(item, 'updated_user'),
        status: this.tableConv.resToColordItem(item, 'lifecycle_status'),
      };
    });
    return result;
  }

  formGroupToForm(formGroup: FormGroup): ProcessFormDTO {
    return {
      id: formGroup.get('id')?.value,
      title_en: formGroup.get('Title')?.value,
      title_ar: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      process_group_id: formGroup.get('ProcessGroup')?.value,
      //owner_unit_id: formGroup.get('ResponsibleUnit')?.value,
      owner_unit_id: 1, // TODO Data should be taken from form
      owner_id: formGroup.get('ProcessOwner')?.value,
      control_ids: formGroup.get('Controls')?.value,
    };
  }
  resToForm(response: any) {
    return {
      id: response.id,
      Title: response.title_en,
      Description: response.description,
      ProcessGroup: response.process_group?.id,
      ResponsibleUnit: response.owner_unit?.id,
      ProcessOwner: response.owner?.id,
      Controls: response.controls.map((control: any) => control.control_id),
    };
  }
  resToDetails(response: any): ProcessDetailsDTO {
    return {
      id: response.id,
      reference_number: response.reference_number,
      title: response[`title_${this.lang}`],
      description: response.description,
      marked_as_auditable_item: response.marked_as_auditable_item,
      process_group: convertResponseToProcessGroupDetailsDTO(
        response.process_group,
        this.lang
      ),
      owner: this.userConv.resObjToUserMinimalDTO(response.owner),
      status: convertResponseToLifeCycleStatusDetailsDTO(
        response.status,
        this.lang
      ),
      created_by: this.userConv.resObjToUserMinimalDTO(response.created_by),
      updated_by: this.userConv.resObjToUserMinimalDTO(response.updated_by),
      created_at: response.created_at,
      updated_at: response.updated_at ? response.updated_at : null,
    };
  }

  resToOverAllScoreChart(totalControlScore: number): pieChartData[] {
    return [
      {
        category: 'Achieved Control Score',
        count: totalControlScore,
        sliceSettings: {
          fill: am5.color("#28A745"),
        },
      },
      {
        category: 'Remaining Control Score',
        count: 100 - totalControlScore,
        sliceSettings: {
          fill: am5.color("#DC3545"),
        },
      },
    ];
  }

  resToImplementationOverviewChart(response: any): pieChartData[] {
    return response.implementation_status.map(
      (implementationStatus: StatusDTO) => {
        return convertResponseToChartData(implementationStatus, this.lang);
      }
    );
  }

  resToEfficiencyBreakdownChart(response: any): pieChartData[] {
    return response.total_efficiency.map((efficiency: StatusDTO) => {
      return convertResponseToChartData(efficiency, this.lang);
    });
  }
}

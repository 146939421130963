export interface ProcessGroupDTO {
  id: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface ProcessGroupFormDTO {
  id?: number;
  title_en: string;
  title_ar: string;
  status: string;
}


export interface ProcessGroupDetailsDTO {
  id: number;
  title: string;
  status: string;
}

export function convertResponseToProcessGroupDetailsDTO(
  response: any,
  lang: string
): ProcessGroupDetailsDTO {
  return {
    id: response.id,
    title: response[`title_${lang}`],
    status: response.status,
  };
}
